import React from "react";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

import styles from "./gallery.module.scss";

const Item: React.FC<{ data: any }> = ({ data }) => (
  <div className={styles.item}>
    <Img fluid={data.childImageSharp.fluid} />
  </div>
);

export const Gallery: React.FC = () => {
  const { img0, img1, img2 } = useStaticQuery(graphql`
    query {
      img0: file(relativePath: { eq: "cafe-paris/cafe-paris-0.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img1: file(relativePath: { eq: "cafe-paris/cafe-paris-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img2: file(relativePath: { eq: "cafe-paris/cafe-paris-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className={styles.wrapper}>
      <Item data={img0} />
      <Item data={img1} />
      <Item data={img2} />
    </div>
  );
};
