import React from "react";

import styles from "./splash.module.scss";

export const Splash: React.FC = () => (
  <div className={styles.wrapper}>
    <img
      src={require("../../../images/locations/cafe-paris-logo.svg")}
      alt="Café Paris Logo"
    />
  </div>
);
