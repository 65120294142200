import React from "react";

import { Title } from "../../shared/title";

import styles from "./schedule.module.scss";

export const Schedule: React.FC = () => (
  <div className={styles.wrapper}>
    <Title inverted>ÖFFNUNGSZEITEN</Title>
    <p>
      Mo. - Fr. 10:00 - 18:00 Uhr
      <br />
      Sa. 9.30 - 16.00 Uhr
      <br />
      Sonntags nur bei Reservierung
    </p>
    <p style={{ color: "white", fontWeight: 700 }}>
      Bei Musik-, Konzert- und Theaterveranstaltungen: Bis 23.00 Uhr
    </p>
  </div>
);
