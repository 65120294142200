import React from "react";
import { Link } from "gatsby";
import cn from "classnames";

import styles from "./button-link.module.scss";

type ButtonLinkProps = {
  to: string;
  inverted?: boolean;
};

export const ButtonLink: React.FC<ButtonLinkProps> = ({
  children,
  to,
  inverted,
}) => (
  <div className={styles.wrapper}>
    <Link to={to} className={cn(styles.link, inverted && styles.inverted)}>
      {children}
    </Link>
  </div>
);
