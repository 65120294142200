import React from "react";

import styles from "./facebook-button.module.scss";

export const FacebookButton: React.FC<{ href: string }> = ({
  children,
  href,
}) => (
  <div className={styles.wrapper}>
    <a
      href={href}
      className={styles.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  </div>
);
