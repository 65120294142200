import React from "react";

import { Layout } from "../components/shared/layout";
import { Helmet } from "../components/shared/helmet";

import { SectionWrapper } from "../components/shared/section-wrapper";
import { ButtonLink } from "../components/shared/button-link";
import { FacebookButton } from "../components/shared/facebook-button";

import { Splash } from "../components/cafe-paris/splash";
import { Gallery } from "../components/cafe-paris/gallery";
import { Schedule } from "../components/cafe-paris/schedule";

export default () => (
  <Layout>
    <Helmet title="Café Paris" />
    <Splash />
    <SectionWrapper>
      <div style={{ paddingTop: 0 }}>
        <p>
          Sie finden das Kultur Café Paris zwischen der Mediothek und dem
          Theater. Es ist der ideale Ort, um den Abend nach einem Theaterstück
          genussvoll ausklingen zu lassen.
        </p>
        <p>
          Alle zwei Wochen, immer mittwochs ab 19 Uhr, sind Musikveranstaltungen
          geplant. Des Weiteren kann man nach Theatervorführungen im Kultur Café
          Paris, Theaterplatz 2, etwas trinken und essen bis ca. 23 Uhr.
        </p>
        <ButtonLink to="/speisekarten/cafe-paris">Zur Speisekarte</ButtonLink>
        <FacebookButton href="https://www.facebook.com/Caf%C3%A9-Paris-417127952173100/">
          Café Paris auf Facebook
        </FacebookButton>
        <Gallery />
      </div>
      <Schedule />
    </SectionWrapper>
  </Layout>
);
